<!--支付模板-->
<template>
    <div class="PayTemplate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>

        <el-form @submit.native.prevent :inline="true" :model="form" :rules="rules" ref="createForm" label-width="80px">
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="630"
                >
                    <el-table-column label="序号" width="60" type="index" fixed="left" />
                    <el-table-column label="名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.names'" :rules="rules.names">
                                <el-input v-model.trim="scope.row.names" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="标志" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.flags'" :rules="rules.flags">
                                <el-input v-model.trim="scope.row.flags" size="mini" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="可支付(收银)" width="110">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canPays"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="可退款(收银)" width="110">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canRefunds"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="可抹零(收银)" width="110">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canFloors"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="可组合支付(收银)" width="130">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canCombinationPays"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="可充值(会员)" width="130">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canRecharges"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="可退款(会员)" width="110">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.canRechargeRefunds"
                                active-color="#7958b5"
                                active-text="开"
                                inactive-color="#e8e4f3"
                                inactive-text="关"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)"> 删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import { duplicateValidateRule } from 'js/validate/ValidateCommonRule';
import StockBizCommon from 'js/StockBizCommon';

export default {
    name: 'PayTemplateCreate',
    data() {
        return {
            detailParamPropertyArr: [
                'names',
                'flags',
                'canPays',
                'canRefunds',
                'canFloors',
                'canCombinationPays',
                'canRecharges',
                'canRechargeRefunds',
            ],
            form: {
                detailParams: [],
            },
            rules: {
                names: [
                    { required: true, message: '请填写名称', trigger: 'blur' },
                    duplicateValidateRule(
                        (currentIdx, name) => {
                            const duplicated = this.form.detailParams.find((e, index) => {
                                return currentIdx !== index && name === e.names;
                            });
                            return duplicated !== undefined;
                        },
                        '名称重复',
                        (currentIdx) => {
                            this.form.detailParams[currentIdx].names = '';
                        }
                    ),
                ],
                flags: [
                    { required: true, message: '请填写标志', trigger: 'blur' },
                    duplicateValidateRule(
                        (currentIdx, flag) => {
                            const duplicated = this.form.detailParams.find((e, index) => {
                                return currentIdx !== index && flag === e.flags;
                            });
                            return duplicated !== undefined;
                        },
                        '标志重复',
                        (currentIdx) => {
                            this.form.detailParams[currentIdx].flags = '';
                        }
                    ),
                ],
            },
        };
    },
    mounted() {},
    computed: {
        //禁用/启用按钮
        disableSaveButton() {
            return this.form.detailParams == null || this.form.detailParams.length === 0;
        },
    },
    methods: {
        handleAdd() {
            this.form.detailParams.push(this.initParam());
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        async handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const createParam = StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                    this.form.detailParams,
                    this.detailParamPropertyArr
                );
                await this.$efApi.payTemplateApi.create(createParam);
                this.goBackAndReload();
            });
        },
        initParam() {
            return {
                names: '',
                flags: '',
                canPays: false,
                canRefunds: false,
                canFloors: false,
                canCombinationPays: false,
                canRecharges: false,
                canRechargeRefunds: false,
            };
        },
    },
};
</script>
